<mat-card appearance="outlined" *ngIf="!isLoading && ((plan && price) || isNoPermission); else loader">
  <ng-container *ngIf="isNoPermission; else planContent">
    <mat-card-header>
      <mat-card-title>{{ 'payments.plan.no_premission.title' | translate }}</mat-card-title>
      <mat-card-subtitle>{{ 'payments.plan.no_premission.description' | translate }}</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <button (click)="changeAccount()" color="primary" mat-raised-button>
        {{ 'payments.plan.no_premission.btn_txt' | translate }}
      </button>
    </mat-card-content>
  </ng-container>

  <ng-template #planContent>
    <mat-card-header>
      <mat-card-title>{{ 'payments.plan.title' | translate }}</mat-card-title>
      <mat-card-subtitle
        >{{ 'payments.plan.subtitle' | translate }}

        <span *ngIf="isTrialAvailable" class="trial-info">{{ 'payments.plan.trial_info' | translate }}</span>
      </mat-card-subtitle>

      <app-currency-chooser (currencyChange)="currencyChange($event)" *ngIf="isAnonymous" [(currency)]="currency"></app-currency-chooser>
    </mat-card-header>

    <mat-card-content>
      <div class="plan-content">
        <div *ngIf="isAnonymous" class="audit">
          <div class="title">{{ 'payments.plan.audit.title' | translate }}</div>

          <div class="details">
            <ul>
              <li>{{ 'payments.plan.audit.details.1' | translate }}</li>
              <li>{{ 'payments.plan.audit.details.2' | translate }}</li>
              <li>{{ 'payments.plan.audit.details.3' | translate }}</li>
              <li>{{ 'payments.plan.audit.details.4' | translate }}</li>
            </ul>
          </div>

          <button (click)="goToAudit()" mat-raised-button>
            {{ 'payments.plan.audit.btn_txt' | translate }}
          </button>
        </div>

        <app-summary
          (buy)="buy()"
          *ngIf="price"
          [isLoadingPlan]="isLoadingPlan"
          [isTrial]="isTrialAvailable"
          [price]="price"
          class="sticky"
        ></app-summary>

        <div class="restrictions">
          <div *ngIf="plan.sembot" class="limits">
            <h2>{{ 'payments.plan.limit.title' | translate }}</h2>
            <p class="desc">{{ 'payments.plan.limit.description' | translate }}</p>

            <div class="siders">
              <div *ngFor="let key of sembotOrder" class="slider">
                <ng-container *ngIf="getFormControl(key)">
                  <div class="label">{{ 'payments.plan.limit.items.' + key + '.label' | translate }}:</div>

                  <div class="value">
                    <mat-slider [max]="sembotConfig[key].max" [min]="sembotConfig[key].min" [step]="sembotConfig[key].step" discrete>
                      <input matSliderThumb [value]="getFormControl(key).value" (valueChange)="getFormControl(key).setValue($event)" />
                    </mat-slider>

                    <div class="field">
                      <div>
                        <mat-icon (click)="subtractOneStep(key)">do_not_disturb_on</mat-icon>
                        <mat-form-field appearance="outline">
                          <input [formControl]="getFormControl(key)" matInput maxlength="6" />
                          <app-form-field-error-display matError></app-form-field-error-display>
                        </mat-form-field>
                        <mat-icon (click)="addOneStep(key)">add_circle</mat-icon>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>

              <div class="slider">
                <mat-menu #helpInfo="matMenu" class="help-info">
                  <div class="desc">{{ 'payments.plan.limit.items.chat_gpt_requests.help_info' | translate }}</div>

                  <button (click)="goToChatGptLanding()" color="primary" mat-raised-button>
                    {{ 'general.read_more' | translate }}
                  </button>
                </mat-menu>

                <div class="label">
                  {{ 'payments.plan.limit.items.chat_gpt_requests.label' | translate }}:
                  <mat-icon [matMenuTriggerFor]="helpInfo">help</mat-icon>
                </div>
                <div class="value">
                  <mat-slider [matMenuTriggerFor]="helpInfo" [max]="1000" [min]="0" [step]="10" disabled="true" thumbLabel
                    ><input matSliderThumb value="100" />
                    <input matSliderThumb />
                  </mat-slider>

                  <div class="field">
                    <div>
                      <mat-icon [matMenuTriggerFor]="helpInfo">do_not_disturb_on</mat-icon>
                      <mat-form-field appearance="outline">
                        <input [matMenuTriggerFor]="helpInfo" matInput readonly="true" value="100" />
                      </mat-form-field>
                      <mat-icon [matMenuTriggerFor]="helpInfo">add_circle</mat-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="selectedSupportProduct" class="support two-cols">
            <h2>{{ 'payments.plan.support.title' | translate }}</h2>
            <p class="desc">{{ 'payments.plan.support.description' | translate }}</p>

            <div class="boxes">
              <app-box
                (selected)="selectSupport($event)"
                *ngFor="let key of supportOrder"
                [currency]="plan.currency"
                [data]="plan.support[key]"
                [isSelected]="selectedSupportProduct && selectedSupportProduct.key === key"
                [isTrial]="isTrialAvailable"
                [key]="key"
                class="box"
                restrictionKey="support_minutes"
                section="support"
              ></app-box>
            </div>
          </div>

          <div *ngIf="selectedCssProduct" class="css">
            <h2>{{ 'payments.plan.css.title' | translate }}</h2>
            <p class="desc">{{ 'payments.plan.css.description' | translate }}</p>

            <div class="boxes">
              <app-box
                (selected)="selectCss($event)"
                *ngFor="let key of cssOrder"
                [currency]="plan.currency"
                [data]="plan.css[key]"
                [isSelected]="selectedCssProduct && selectedCssProduct.key === key"
                [isTrial]="isTrialAvailable"
                [key]="key"
                class="box"
                restrictionKey="css_markets"
                section="css"
              ></app-box>
            </div>
          </div>
        </div>

        <app-summary (buy)="buy()" *ngIf="price" [isLoadingPlan]="isLoadingPlan" [isTrial]="isTrialAvailable" [price]="price"></app-summary>
      </div>
    </mat-card-content>
  </ng-template>
</mat-card>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner [diameter]="40" mode="indeterminate"></mat-spinner>
  </div>
</ng-template>
