import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep, get, groupBy, intersection, mapValues } from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { NotificationService } from 'src/app/notification/notification.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { LocalStorageService, StartPageStorageEnum, storageKey } from 'src/app/shared/service/local-storage.service';
import { RouterService, routerConfig } from 'src/app/shared/service/router.service';
import { LangEnum, TranslationService } from 'src/app/shared/service/translation.service';
import { RangeValidation } from 'src/app/shared/validators/range-validation.service';
import { AccountChangePopupComponent } from '../../navigation/modals/account-change-popup/account-change-popup.component';
import { PaymentPlanCssEnum, PaymentPlanSembotEnum, PaymentPlanSupportEnum } from '../models/payments.enum';
import {
  CurrencyEnum,
  PaymentPlanModel,
  PaymentPlanProductItemModel,
  PaymentPlanProductsModel,
  Restrictions,
  SelectedProductModel,
  SubscriptionPriceModel,
} from '../models/payments.model';
import { PaymentsService } from '../payments.service';

const DEFAULT_CURRENCY = CurrencyEnum.eur;
const MAX_VALUE = 999999;
const SUPPORTED_CURRENCIES = [CurrencyEnum.pln, CurrencyEnum.usd, CurrencyEnum.gbp, CurrencyEnum.eur];

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss'],
})
export class PlanComponent implements OnInit, OnDestroy {
  @Output() fault: EventEmitter<boolean> = new EventEmitter();
  @Output() submitted: EventEmitter<boolean> = new EventEmitter();
  // currentPlan: CurrentPackage; // TODO: change to CurrentPlanModel
  additionalProducts: { id: number; count: number; key: string; name: string; value: number }[] = [];
  cssOrder = [PaymentPlanCssEnum.free, PaymentPlanCssEnum.brand, PaymentPlanCssEnum.whitelabel];
  currency: CurrencyEnum = DEFAULT_CURRENCY;
  currentValues!: {};
  form!: UntypedFormGroup;
  isAnonymous: boolean = false;
  isLoading: boolean;
  isNoPermission: boolean = false;
  plan!: PaymentPlanModel;
  price!: SubscriptionPriceModel;
  selectedCssProduct: SelectedProductModel = { key: PaymentPlanCssEnum.free };
  selectedSupportProduct: SelectedProductModel = { key: PaymentPlanSupportEnum.free };
  subscriptionProductid!: number;
  supportOrder = [PaymentPlanSupportEnum.free, PaymentPlanSupportEnum.activeService];
  // PaymentPlanSupportEnum.allInOne - @TMP: temporary removal of All in one: #asana-1207432193547201
  protected sembotOrder = [
    PaymentPlanSembotEnum.outputProducts,
    PaymentPlanSembotEnum.monitorProducts,
    PaymentPlanSembotEnum.monitorPhrases,
    PaymentPlanSembotEnum.projects,
  ];

  protected sembotConfig = {
    [PaymentPlanSembotEnum.outputProducts]: {
      basic: 0,
      max: 100000,
      min: 1000,
      step: 1000,
    },
    [PaymentPlanSembotEnum.monitorProducts]: {
      basic: 0,
      max: 10000,
      min: 100,
      step: 100,
    },
    [PaymentPlanSembotEnum.monitorPhrases]: {
      basic: 0,
      max: 10000,
      min: 100,
      step: 100,
    },
    [PaymentPlanSembotEnum.projects]: {
      basic: 0,
      max: 200,
      min: 1,
      step: 1,
    },
  };

  protected isLoadingPlan!: boolean;
  private destroy$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private paymentsService: PaymentsService,
    private routerService: RouterService,
    private storageHelperService: LocalStorageService,
    private translationService: TranslationService,
  ) {
    this.isLoading = true;
  }

  get isTrialAvailable(): boolean {
    return this.plan! && this.plan.is_trial_available! && this.price! && this.price.is_trial_available!;
  }

  ngOnInit(): void {
    this.price = {
      amount: 0,
      currency: this.currency,
    };

    if (this.authService.authUser) {
      const { permissions } = this.authService.authUser;

      if (permissions!.includes('manage payment')) {
        this.getPlanParams();
      } else {
        this.isNoPermission = true;
        this.isLoading = false;
      }
    } else {
      const { currency, lang } = this.activatedRoute.snapshot.queryParams;

      currency && SUPPORTED_CURRENCIES.includes(currency) && (this.currency = currency);
      lang && this.setLang(lang);

      this.isAnonymous = true;
      this.getPlanParams();
    }
  }

  addOneStep(key: PaymentPlanSembotEnum) {
    this.setOneStepValue(key, true);
  }

  subtractOneStep(key: PaymentPlanSembotEnum) {
    this.setOneStepValue(key, false);
  }

  changeAccount() {
    const config = {
      width: '400px',
    } as MatDialogConfig;
    this.dialog.open(AccountChangePopupComponent, config);
  }

  getPlanParams() {
    this.isLoadingPlan = true;
    const currency = this.isAnonymous ? this.currency : null;

    this.paymentsService
      .getPlan(currency)
      .pipe(
        tap((plan) => {
          // @TMP: temporary removal of All in one: #asana-1207432193547201
          plan?.support?.all_in_one && delete plan.support.all_in_one;

          this.plan = plan;

          if (plan) {
            plan.basic?.restrictions && this.setRestrictions(plan.basic.restrictions);
            plan.sembot && this.createForm(plan.sembot);
          } else {
            this.notificationService.error('general');
            this.fault.emit(true);
          }
        }),
      )
      .subscribe(
        () => {
          this.getCurrentPlan();
          this.isLoadingPlan = false;
        },
        () => {
          this.notificationService.error('general');
          this.fault.emit(true);
          this.isLoadingPlan = false;
        },
      );
  }

  goToAudit() {
    if (this.isAnonymous) {
      this.storageHelperService.save(storageKey.startPage, StartPageStorageEnum.projectAudit);
      this.routerService.navigateBlank(routerConfig.authRegister, { type: 'audit' });
    }
  }

  buy() {
    if (this.form.invalid) {
      this.notificationService.error('subscription_buy_params');
      return false;
    }

    this.isLoading = true;
    this.savePlanParamsToStorage();

    if (this.isAnonymous) {
      this.routerService.navigateBlank(routerConfig.authRegister);
    } else {
      if (this.paymentsService.currentPackage$.getValue()?.stripe_status) {
        this.paymentsService.changePackage(this.subscriptionProductid, this.additionalProducts!).subscribe(
          () => {
            this.submitted.emit(true);
            this.notificationService.success('subscription_buy');
            setTimeout(() => this.routerService.navigate(routerConfig.payments), 1500);
          },
          (err) => {
            this.submitted.emit(false);
            this.notificationService.warning('subscription_buy');
            this.isLoading = false;
            console.log({ err });
          },
        );
      } else {
        this.paymentsService.getPaymentCheckoutUrl(this.subscriptionProductid, this.additionalProducts!).subscribe(
          (url: string) => {
            url && (window.location.href = url);
          },
          (err) => {
            this.submitted.emit(false);
            this.notificationService.warning('subscription_buy');
            this.isLoading = false;
            console.log({ err });
          },
        );
      }
    }
  }

  getCurrentPlan(): void {
    if (this.isAnonymous) {
      this.getPlanParamsFromStorage();
      this.markAsLoaded();
    } else {
      this.paymentsService
        .getCurrentPackage()
        .pipe(
          tap((plan) => {
            const { items, restrictions } = plan;

            if (items && items.length && restrictions && Object.keys(restrictions).length) {
              const slectedProduct = (product: PaymentPlanProductItemModel | null, section: string) => {
                if (product && product.prod_name) {
                  const key = product.prod_name;

                  const currentItem = ((this.plan[section as keyof typeof this.plan] as any)[key] as any).list.find(
                    (item: any) => item.id === product.id,
                  );
                  currentItem && (currentItem.isSelected = true);

                  return { key, product };
                }

                return null;
              };

              const itemsByType = groupBy(items, 'type');
              const css = slectedProduct(get(itemsByType, 'css[0]', null) as any, 'css');
              const support = slectedProduct(get(itemsByType, 'support[0]', null) as any, 'support');

              css && (this.selectedCssProduct = css);
              support && (this.selectedSupportProduct = support);

              this.setCurrentRestrictions(restrictions);
            } else {
              this.getPlanParamsFromStorage();
            }
          }),
        )
        .subscribe(
          () => this.markAsLoaded(),
          (err) => {
            this.isLoading = false;
            console.log({ err });
          },
        );
    }
  }

  getFormControl(key: string) {
    return this.form.get(key) as UntypedFormControl;
  }

  selectSupport(data: SelectedProductModel) {
    this.selectedSupportProduct = data;
    this.recalculate();
  }

  selectCss(data: SelectedProductModel) {
    this.selectedCssProduct = data;
    this.recalculate();
  }

  goToChatGptLanding() {
    const currentLang = this.translationService.storageLang;
    window.open(
      currentLang === LangEnum.pl ? 'https://pl.sembot.com/sembot-chat-gpt-plug-in' : 'https://sembot.com/sembot-chat-gpt-plug-in',
      '_blank',
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected currencyChange(currency: CurrencyEnum) {
    this.currency = currency;
    if (this.currency && SUPPORTED_CURRENCIES.includes(this.currency)) {
      this.isLoading = true;
      this.getPlanParams();
    }
  }

  private setupValueChanges() {
    this.sembotOrder.forEach((key) => {
      const control = this.getFormControl(key);

      control &&
        control.valueChanges.pipe(debounceTime(800), takeUntil(this.destroy$)).subscribe((newValue) => {
          this.handleControlValueChange(key, newValue);
        });
    });
  }

  private handleControlValueChange(key: PaymentPlanSembotEnum, newValue: string) {
    const numericValue = Number(newValue);
    if (!isNaN(numericValue) && !this.isMultipleOfStep(key, numericValue)) {
      const stepSize = this.sembotConfig[key].step;
      const roundedValue = Math.ceil(numericValue / stepSize) * stepSize;
      this.getFormControl(key).setValue(roundedValue, { emitEvent: false });
      this.recalculate();
    }
  }

  private isMultipleOfStep(key: PaymentPlanSembotEnum, value: number): boolean {
    const { step } = this.sembotConfig[key];
    return value % step === 0;
  }

  private calculateCounter(value: number, key: PaymentPlanSembotEnum | string): number {
    const config = this.sembotConfig[key as keyof typeof this.sembotConfig];
    const step = this.sembotConfig[key as keyof typeof this.sembotConfig].step || 1;

    let diffValue = value - (config.basic || 0);
    diffValue = diffValue < 0 ? 0 : diffValue;

    const divided = diffValue % step;

    if (divided > 0) {
      diffValue = diffValue - divided;
    }

    return diffValue / step;
  }

  private createForm(products: PaymentPlanProductsModel) {
    const formGroupControls: { [key: string]: AbstractControl } = {};
    Object.keys(products).forEach((key: PaymentPlanSupportEnum | any) => {
      if (Object.keys(this.sembotConfig).includes(key)) {
        const product = products[key].list[0];
        const restriction = product.restrictions[key as keyof typeof product.restrictions] as number;

        if (restriction) {
          const config = (this.sembotConfig as any)[key];
          const minValue = config.basic && config.basic > restriction ? config.basic : restriction;

          config.min = minValue;
          config.step = restriction;
          const range = { min: config.min, max: MAX_VALUE };

          formGroupControls[key] = new UntypedFormControl(minValue, RangeValidation.range(range));
          // eslint-disable-next-line @typescript-eslint/dot-notation
          (formGroupControls[key] as any)['_productId'] = product.id;
        }
      }
    });

    this.form = new UntypedFormGroup(formGroupControls);
    this.recalculate();

    this.form.valueChanges.subscribe(() => this.recalculate());
    this.setupValueChanges();
  }

  private getPlanParamsFromStorage() {
    const restrictionsFromStorage = this.storageHelperService.get(storageKey.paymentPlanParams);

    if (restrictionsFromStorage) {
      const key = (plan: PaymentPlanProductsModel) => {
        const commonKeys = intersection(Object.keys(plan), Object.keys(restrictionsFromStorage));
        let resp = null;

        if (commonKeys && commonKeys[0] && restrictionsFromStorage[commonKeys[0]].id) {
          const firstKey = commonKeys[0];
          const product = plan[firstKey].list.find((item) => item.id === restrictionsFromStorage[firstKey].id);

          if (product) {
            product.isSelected = true;
            resp = { key: firstKey, product };
          }
        }

        return resp;
      };

      const css = key(this.plan.css);
      const support = key(this.plan.support);

      css && (this.selectedCssProduct = css);
      support && (this.selectedSupportProduct = support);

      this.setCurrentRestrictions(mapValues(restrictionsFromStorage, 'val') as Restrictions);
      this.form.markAllAsTouched();
    }
  }

  private markAsLoaded() {
    setTimeout(() => (this.isLoading = false), 2000);
  }

  private setLang(lang = this.currency === CurrencyEnum.pln ? LangEnum.pl : LangEnum.en) {
    this.translationService.setCustomLang(lang);
  }

  private recalculate() {
    this.isLoadingPlan = true;
    this.additionalProducts = [];
    const basicSubscription = this.plan.basic;
    this.subscriptionProductid = basicSubscription!.id ? basicSubscription!.id : null!;
    let products: {
      id: number;
      count?: number;
    }[] = this.subscriptionProductid ? [{ id: this.subscriptionProductid }] : [];

    for (const key in this.form.controls) {
      const control = this.getFormControl(key);
      const value = Number(control.value);
      // eslint-disable-next-line @typescript-eslint/dot-notation
      const projectId = Number((control as any)['_productId']);
      const count = this.calculateCounter(value, key);

      projectId && count && this.setProduct(key, projectId, count, value);
    }

    const setPreparedProduct = (selectedProduct: SelectedProductModel, restrictionKey: string = '') => {
      const { key, product } = selectedProduct;
      const { id = 0, restrictions = {} } = product || {};
      const restrictionValue =
        restrictionKey && restrictions[restrictionKey as keyof typeof restrictions]
          ? restrictions[restrictionKey as keyof typeof restrictions]
          : null!;
      this.setProduct(key, id, 1, restrictionValue, restrictionKey);
    };

    setPreparedProduct(this.selectedSupportProduct, 'support_minutes');
    setPreparedProduct(this.selectedCssProduct, 'css_markets');

    products = products.concat(this.additionalProducts);

    (this.isAnonymous
      ? this.paymentsService.getSubscriptionGuestPrice(this.currency, null, products)
      : this.paymentsService.getSubscriptionPrice(null, products)
    ).subscribe(
      (res) => {
        this.price = res;
        this.isLoadingPlan = false;
      },
      (err) => {
        this.notificationService.warning('subscription_buy');
        console.log({ err });
        this.isLoadingPlan = false;
      },
    );
  }

  private savePlanParamsToStorage() {
    const products = {};

    cloneDeep(this.additionalProducts).forEach(
      (product) =>
        ((products[product.key as keyof typeof products] as any) = {
          id: product.id,
          val: product.value,
        }),
    );

    Object.keys(products).length && this.storageHelperService.save(storageKey.paymentPlanParams, products);
  }

  private setOneStepValue(key: PaymentPlanSembotEnum, isAdd: boolean) {
    if (key && this.sembotConfig[key]) {
      const step = this.sembotConfig[key].step || null;
      const currentValue = Number(this.getFormControl(key).value);

      if (currentValue && step) {
        const newValue = isAdd ? currentValue + step : currentValue - step;

        if (newValue >= this.sembotConfig[key].min && newValue <= MAX_VALUE) {
          this.getFormControl(key).setValue(newValue);
        }
      } else {
        this.getFormControl(key).setValue(this.sembotConfig[key].min || 1);
      }
    }
  }

  private setProduct(key: string, projectId: number, projectCount: number, value: number, name: string | null = null) {
    const id = Number(projectId);
    const count = Number(projectCount);

    if (id && count) {
      this.additionalProducts.push({ id, count, key, name: name!, value });
    }
  }

  private setRestrictions(restrictions: Restrictions) {
    Object.keys(restrictions).forEach((key: PaymentPlanSembotEnum | any) => {
      if (this.sembotConfig[key as keyof typeof this.sembotConfig]) {
        const basic = restrictions[key as keyof typeof restrictions] || 0;
        (this.sembotConfig[key as keyof typeof this.sembotConfig] as any).basic = basic;
      }
    });
  }

  private setCurrentRestrictions(restrictions: Restrictions) {
    const formGroupControls = {};
    Object.keys(restrictions).forEach(
      (key: PaymentPlanSembotEnum | any) =>
        this.getFormControl(key) &&
        ((formGroupControls[key as keyof typeof formGroupControls] as any) = restrictions[key as keyof typeof restrictions]),
    );

    this.form.patchValue(formGroupControls);
    this.form.markAllAsTouched();
  }
}
