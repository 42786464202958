<mat-card appearance="outlined" [ngClass]="{ selected: isSelected }">
  <mat-card-header>
    <mat-card-title>{{ translator.title }}</mat-card-title>
    <mat-card-subtitle>{{ translator.description }}</mat-card-subtitle>
  </mat-card-header>

  <mat-card-actions>
    <button mat-raised-button color="primary" (click)="select()">{{ 'payments.plan.select' | translate }}</button>
  </mat-card-actions>

  <mat-card-content>
    <div class="price" *ngIf="currenItem.price || currenItem.price === 0">
      <span *ngIf="currenItem.price">+</span>{{ currenItem.price / 100 | number: '1.2-2' }}<span class="currency">{{ currency }}</span>
      <span>/ {{ 'payments.plan.monthly_subscription' | translate }}</span>
    </div>
    <div class="price" *ngIf="currenItem.setup?.price">
      <span *ngIf="currenItem.setup!.price!">+</span>{{ currenItem.setup!.price! / 100 | number: '1.2-2'
      }}<span class="currency">{{ currency }}</span>
      <span>/ {{ 'payments.plan.setup_fees' | translate }}</span>
    </div>

    <div *ngIf="restrictionKey && restrictions && restrictions.length > 1" class="restrictions">
      <mat-label>
        {{ 'payments.plan.restriction.' + restrictionKey + '.label' | translate }}
      </mat-label>
      <mat-form-field appearance="outline">
        <mat-select [(value)]="currenItem" (selectionChange)="select()">
          <mat-option *ngFor="let item of restrictions" [value]="item">
            {{
              'payments.plan.restriction.' + restrictionKey + '.items.' + (prepareRestriction(item.restrictions[restrictionKey]) || 0)
                | translate
            }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="translator.detailed">
      <div *ngFor="let detail of translator.detailed" class="items">
        <h4 *ngIf="detail.title">{{ detail.title }}:</h4>
        <ul *ngIf="detail.list">
          <li *ngFor="let item of detail.list"><mat-icon>check_circle</mat-icon> {{ item }}</li>
        </ul>
      </div>
    </div>

    <span *ngIf="isTrial && data.isTrial" class="trial-label">{{ 'payments.plan.free_version' | translate }}</span>

    <a *ngIf="data.learnMoreUrl" class="learn-more" [href]="data.learnMoreUrl" target="_blank"
      >{{ 'general.learn_more' | translate }} <mat-icon>arrow_right_alt</mat-icon></a
    >
  </mat-card-content>
</mat-card>
