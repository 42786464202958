import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PluginService } from '../shared/service/plugin.service';

@Component({
  selector: 'app-shopify-after-payment',
  template: '',
})
export class ShopifyAfterPaymentComponent implements OnInit {
  constructor(
    private pluginService: PluginService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    console.log('ShopifyAfterPaymentComponent');
    const url = new URL(window.location.href);
    const userId = url.searchParams.get('user_id');
    const packageId = url.searchParams.get('package_pricing_id');
    const chargeId = url.searchParams.get('charge_id');

    this.pluginService.sendAfterPackageShopify(+packageId!, +chargeId!, +userId!).subscribe(() => this.redirectToShopify());
  }

  redirectToShopify() {
    const { shop } = this.activatedRoute.snapshot.queryParams;
    window.location.href = `https://${shop}/admin/apps/${environment.shopifyName}/front/dashboard/payments`;
  }
}
