import { Component, EventEmitter, Input, OnDestroy, Output, inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ProjectService } from 'src/app/dashboard/project/project.service';
import { ConnectionsServiceEnum } from 'src/app/shared/enums/connections-service.enum';
import { ConnectionsService } from 'src/app/shared/service/connections.service';
import { ConnectionModel } from '../../../../../shared/model/connections.model';
import { FormApiValidationError } from '../../../../../shared/model/errors/formApiError.model';

@Component({
  selector: 'app-gsc-service',
  templateUrl: './gsc-service.component.html',
})
export class GscServiceComponent implements OnDestroy {
  @Input() connections: ConnectionModel[] = [];
  @Output() connectionAttached = new EventEmitter<boolean>();

  sitesList: { url: string }[] = [];
  isLoading = false;

  service = ConnectionsServiceEnum.Google;

  private onDestroy$ = new Subject<void>();

  private fb = inject(FormBuilder);
  private connectionsService = inject(ConnectionsService);
  private projectService = inject(ProjectService);

  form = this.fb.group({
    connection: [null, Validators.required],
    site: [null, Validators.required],
  });

  get connection(): FormControl {
    return this.form.get('connection') as FormControl;
  }

  get site(): FormControl {
    return this.form.get('site') as FormControl;
  }

  changeConnection() {
    const { id } = this.connection.value;
    id &&
      this.connectionsService.getGscSites(id).subscribe(
        (res) => (this.sitesList = res.data || []),
        () => this.site.setValue(null),
      );
  }

  submit() {
    this.isLoading = true;
    this.connectionsService
      .attachGscToProject(this.projectService.activeProject$.getValue()!.id, this.connection.value.id, this.site.value.url)
      .pipe(
        tap(() => {
          this.form.reset();
          this.form.markAsUntouched();
          this.isLoading = false;
        }),
        takeUntil(this.onDestroy$),
      )
      .subscribe(
        () => this.connectionAttached.emit(true),
        (er: FormApiValidationError) => {
          er.setOnForm && er.setOnForm(this.form);
          this.connectionAttached.emit(false);
        },
      );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
