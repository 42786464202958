import { PaymentPlanCssEnum, PaymentPlanSupportEnum } from './payments.enum';
import { StripeStatusEnum } from './stripe-status.enum';

export enum CurrencyEnum {
  pln = 'pln',
  usd = 'usd',
  gbp = 'gbp',
  eur = 'eur',
}

export interface PaymentModel {
  id: number;
  price: number;
  currency: string;
  modules: string[];
  restrictions: Restrictions;
  package_name: string;
  description: string;
  products: PaymentProductModel[];
  elements: { [key: string]: PaymentElementModel };
  tax: any;
  trial: boolean;
}

export class PaymentElementModel {
  constructor(
    public name: string,
    public value: string | boolean,
    public type: 'string' | 'boolean',
    public amount?: string,
  ) {}
}

export interface PaymentDataModel {
  clientSecret: string;
  isCancelled: boolean;
  isSucceeded: boolean;
  amount: string;
  requiresPaymentMethod: boolean;
  requiresAction: boolean;
  requiresConfirmation: boolean;
  paymentMethod: string;
}

export interface PaymentProductModel {
  id: number;
  currency: string;
  price: number;
  restrictions: Restrictions;

  prod_name?: string;
  recurring?: boolean;
  setup_id?: boolean;
  trial?: boolean;
  type?: string;
}

export interface PaymentDiscountModel {
  amount_off: number;
  currency: string;
  ends_at: string;
  name: string;
  percent_off: number;
}

export interface SubscriptionPriceModel {
  amount: number;
  currency: string;
  discount?: PaymentDiscountModel;
  is_trial_available?: boolean;
  setup?: number;
}

export interface CurrentPackage {
  cardBrand: string;
  cardLastFour: string;
  created_at: Date;
  customerPortalUrl?: string;
  discount?: PaymentDiscountModel;
  ends_at?: Date;
  items: PackageItemModel[];
  last_payment_id: string;
  name: string;
  restrictions: Restrictions;
  stripe_status: StripeStatusEnum;
  updated_at: Date;
  used?: Restrictions;

  trial_ends_at: string;

  isCancelled: boolean;
  isIncomplete: boolean;
  isActive: boolean;
  isTrialling: boolean;
  isPastDue: boolean;
  isFremium: boolean;
}

export interface PackageItemModel {
  count: number;
  currency: string;
  description: string;
  id: number;
  main: number;
  package_name: string;
  price: number;
  prod_name?: string;
  restrictionNames?: string[];
  restrictions: Restrictions;
}

export interface Restrictions {
  campaigns?: number;
  css_markets?: number;
  monitor_phrases: string;
  monitor_products: string;
  output_products: string;
  projects: string;
  support_minutes?: number;
}

export interface PaymentPlanProductItemModel {
  currency?: string;
  id: number;
  isSelected?: boolean;
  price?: number;
  prod_name: string;
  products?: PaymentProductModel[];
  recurring?: boolean;
  restrictions: Restrictions;
  setup?: { id: string; price: number };
  setup_id?: boolean;
  trial?: boolean;
}

export interface PaymentPlanProductModel {
  isSelected?: boolean;
  isTrial?: boolean;
  learnMoreUrl?: string;
  list: PaymentPlanProductItemModel[];
  price?: number;
  restrictions?: any; // { [key: string]: number };
}

export interface PaymentPlanProductsModel {
  [key: string]: PaymentPlanProductModel;
}

export interface PaymentPlanModel {
  basic?: PaymentPlanProductItemModel;
  css: PaymentPlanProductsModel;
  currency: CurrencyEnum;
  is_trial_available: boolean;
  sembot: PaymentPlanProductsModel;
  support: PaymentPlanProductsModel;
}

export interface SelectedProductModel {
  key: PaymentPlanSupportEnum | PaymentPlanCssEnum | string;
  product?: PaymentPlanProductItemModel;
}
