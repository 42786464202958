import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'lodash';
import { PaymentPlanCssEnum, PaymentPlanSupportEnum } from '../../models/payments.enum';
import { PaymentPlanProductItemModel, PaymentPlanProductModel, SelectedProductModel } from '../../models/payments.model';

type SectionType = 'support' | 'css';
type RestrictionType = 'css_markets' | 'support_minutes';

@Component({
  selector: 'app-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.scss'],
})
export class BoxComponent implements OnInit {
  @Input() currency!: string;
  @Input() data!: PaymentPlanProductModel;
  @Input() isSelected!: boolean;
  @Input() isTrial!: boolean;
  @Input() key!: PaymentPlanSupportEnum | PaymentPlanCssEnum;
  @Input() restrictionKey!: RestrictionType;
  @Input() section!: SectionType;

  @Output() selected = new EventEmitter<SelectedProductModel>();

  get translator() {
    return this.translateService.instant(`payments.plan.${this.section}.items.${this.key}`);
  }

  currenItem!: PaymentPlanProductItemModel;
  restrictions: PaymentPlanProductItemModel[] | any;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    if (this.data && this.data.list) {
      const customCurrentItem = this.data.list.find((item) => item.isSelected);
      if (customCurrentItem) {
        this.currenItem = this.data.list.find((item) => item.isSelected)!;
      } else {
        this.currenItem = this.data.list[0];
      }

      this.restrictions = filter(this.data.list, 'restrictions'); // `restrictions.${this.restrictionKey}`
    }
  }

  select() {
    this.selected.emit({
      key: this.key,
      product: this.currenItem,
    });
  }

  prepareRestriction(value: number): number {
    let returnValue = value;

    if (this.restrictionKey === 'support_minutes') {
      returnValue = Math.round(returnValue / 60); // Minutes -> hours
    }

    return returnValue;
  }
}
